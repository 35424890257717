import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../Data/Context";
// import { Container } from "reactstrap";
import { Buttons } from "../Utils";
import { ProductList } from "../Components/Dashboard";
import { toast } from "react-toastify";

const Documentation = () => {
	let { setStateName, documentation, manageDocumentation, auth } =
			useContext(GlobalState),
		[loading, setLoading] = useState(""),
		[webhook, setWebhook] = useState("");

	useEffect(() => {
		setStateName("API Documentation");
		manageDocumentation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (documentation?.doc) setWebhook(documentation?.doc?.webhook);
	}, [documentation?.doc]);

	return (
		<>
			<div className="bg-white aboutScreen">
				<div className="py-5 tw-px-5 md:tw-px-10">
					<div className="py-3">
						<a
							target={"_blank"}
							rel="noreferrer"
							className="btn-primary1 text-capitalize p-3 px-md-5 text-decoration-none"
							style={{ borderRadius: "30px" }}
							href={process.env.REACT_APP_API_DOC || "#"}>
							<span className="text nav-text text-capitalize">
								API Documentation Page
							</span>
						</a>
						<div className="py-5">
							{documentation?.doc ? (
								["agent"]?.includes(auth?.user?.privilege) && (
									<>
										<div className="list-group-item-light py-3 row mx-0 text2p mb-3 fw-bold">
											<p
												className="col-md-6 my-0"
												style={{ fontSize: "1.2rem" }}>
												Authorization
											</p>
											<p
												className="col-md-6 my-0"
												style={{ fontSize: "1.3rem" }}>
												Bearer {documentation?.doc?.live_key}
											</p>
										</div>
										<div className="list-group-item-light py-3 row mx-0 text2p mb-3 fw-bold">
											<p
												className="col-md-6 my-0"
												style={{ fontSize: "1.2rem" }}>
												Webhook
											</p>
											<p
												className="col-md-6 my-0"
												style={{ fontSize: "1.3rem" }}>
												<input
													type="url"
													name="webhook"
													value={webhook}
													onChange={e => setWebhook(e.target.value)}
													className="form-control py-3"
													defaultValue={documentation?.doc?.wehhook}
												/>
												<div className="w-100 d-flex justify-content-end py-3">
													<div className="btn-group mx-auto w-50">
														{webhook && (
															<Buttons
																onClick={async () => {
																	setLoading("update");
																	await manageDocumentation("post", {
																		webhook,
																	});
																	setLoading();
																}}
																loading={loading === "update"}
																title={"update"}
																css="btn btn-success2 text-uppercase py-3"
																width={"auto"}
															/>
														)}
														{documentation?.doc?.webhook && (
															<Buttons
																onClick={async () => {
																	setLoading("remove");
																	await manageDocumentation("post", "delete");
																	setLoading();
																}}
																loading={loading === "remove"}
																title={"remove"}
																css="btn btn-danger text-uppercase py-3"
																width={"auto"}
															/>
														)}
													</div>
												</div>
											</p>
										</div>
									</>
								)
							) : (
								<p>
									You have not genetated an API KEY yet, Generate api key below
								</p>
							)}
							<Buttons
								title={
									documentation?.doc
										? "Generate new API key"
										: "Generate API Key"
								}
								css="btn-primary1 text-capitalize p-3 px-md-5"
								width={"w-auto ms-auto"}
								onClick={async () => {
									if (!["agent"]?.includes(auth?.user?.privilege)) {
										toast.info(
											`You must become an agent before generating an API key. Please apply as an agent and return to generate your API key once approved.`
										);
										return;
									}
									setLoading("generate");
									await manageDocumentation("post");
									setLoading();
								}}
								style={{ borderRadius: "30px" }}
								loading={loading === "generate"}
							/>
						</div>
					</div>
					<ProductList />
				</div>
			</div>
		</>
	);
};

export default Documentation;

import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../Data/Context";
import { useNavigate, Link } from "react-router-dom";
import { Buttons, EyeToggle } from "../Utils";
// import { DefaultAuthComponent } from "./register";
// import { LogoHeader } from "../Components/Header";
import Loginbg from "../Assets/loginbg.png";
// import Img from "../Assets/loginimg.png";
// import Phone from "../Assets/phone.svg";
import Lock from "../Assets/lock.svg";
import { AiOutlineMail } from "react-icons/ai";
// import { IconContext } from "react-icons";
import axios from "axios";
import { toast } from "react-toastify";
import { ModalComponents } from "../Components/DefaultHeader";
import { PasswordBox } from "./forget-password";

const Login = () => {
  const { loginUser } = useContext(GlobalState);
  // console.log("auth", auth);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let [typePass, setTypePass] = useState(false),
    init = {
      email: "",
      password: "",
    },
    [stateData, setStateData] = useState(init),
    [loading, setLoading] = useState(false),
    navigate = useNavigate(),
    textChange =
      (name) =>
      ({ target: { value } }) => {
        setStateData({ ...stateData, [name]: value });
      },
    [resend, setResend] = useState(false);

  let handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const lg = await loginUser(stateData);

    setLoading(false);
    if (lg) {
      navigate("/dashboard");
    }
  };

  return (
    <div
      style={{
        background: `url(${Loginbg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="tw-w-full tw-h-screen tw-relative tw-px-10"
    >
      <div className="tw-flex tw-justify-between tw-h-full tw-items-center container">
        <div className="tw-relative tw-h-full">
          <div className="tw-mx-auto tw-hidden lg:tw-block tw-absolute tw-w-[500px] tw-bottom-20">
            <h1 className="tw-text-6xl general tw-text-black tw-font-bold tw-text-center">
              Pay your bills <br /> without{" "}
              <span className="tw-text-6xl general tw-text-[#2F75FD] tw-font-extrabold tw-text-center">
                Stress
              </span>
            </h1>
          </div>
        </div>

        <div className="tw-bg-white tw-rounded-2xl tw-bg-opacity-20 tw-backdrop-blur-[15px] tw-shadow-md tw-pt-24 tw-px-14 tw-pb-16 lg:tw-w-[508px] tw-w-full">
          <div onClick={() => navigate("/")} className="">
            <img
              src={process.env.REACT_APP_IMAGE_URL}
              alt={`logo ${process.env.REACT_APP_NAME}`}
              className="tw-h-16"
            />
          </div>
          <div>
            <h3 className="tw-text-capitalize tw-py-5 tw-font-semibold general tw-text-black tw-text-3xl tw-text-left">
              Login
            </h3>
            <p className="general tw-text-base tw-pb-5 tw-font-medium tw-text-black">
              Put in your email and password to get started
            </p>
            <form className="mt-1" onSubmit={handleSubmit}>
              <div className="mb-8">
                <div
                  style={{
                    borderRadius: "8px",
                    border: "2px solid #000",
                    backdropFilter: "blur(2px)",
                  }}
                  className="tw-relative tw-flex tw-items-center tw-w-full tw-h-14 tw-bg-white"
                >
                  <div className="tw-absolute tw-top-4 tw-left-4">
                    <AiOutlineMail size={15} />
                  </div>

                  <input
                    type="email"
                    required
                    name="email"
                    className="tw-h-full tw-text-black tw-pl-12 tw-w-full tw-font-medium tw-text-xl general tw-rounded-lg"
                    placeholder="Email"
                    value={stateData.email}
                    onChange={textChange("email")}
                  />
                </div>
              </div>
              <div
                style={{
                  borderRadius: "8px",
                  border: " 2px solid #000",
                  backdropFilter: "blur(2px)",
                }}
                className="tw-relative tw-flex tw-gap-3 tw-items-center tw-w-full tw-h-14 tw-bg-white"
              >
                <img src={Lock} alt="" className="tw-left-4 tw-absolute" />
                <div className="tw-flex tw-justify-between tw-h-full tw-w-full tw-pl-12 tw-items-center">
                  <input
                    type={typePass ? "text" : "password"}
                    required
                    style={{
                      border: "none !important",
                    }}
                    name="password"
                    className="tw-w-full tw-h-full tw-border-none tw-text-black tw-font-normal tw-text-xl general tw-rounded-lg"
                    value={stateData.password}
                    onChange={textChange("password")}
                    placeholder="Password"
                  />
                  <div className="tw-absolute tw-top-4 tw-right-4">
                    <EyeToggle typePass={typePass} setTypePass={setTypePass} />
                  </div>
                </div>
              </div>
              <p className="tw-text-xs general tw-font-medium tw-text-black tw-pt-8">
                Forgot Password?{" "}
                <Link
                  to={`/forget-password`}
                  className="tw-text-xs general tw-font-bold tw-text-black tw-underline"
                >
                  Recover Password
                </Link>{" "}
              </p>
              <div className="tw-mt-8">
                <Buttons
                  onClick={handleSubmit}
                  loading={loading}
                  title={"sign in"}
                  css="btn-primary1 text-capitalize py-3 w-100 my-2"
                />
              </div>
              <p className="tw-text-center tw-font-semibold tw-text-sm tw-text-black general tw-pt-10">
                Don’t have an account? -{" "}
                <Link
                  to={"/register"}
                  className="tw-text-sm tw-text-[#2F75FD] general tw-font-semibold"
                >
                  Sign Up
                </Link>
              </p>
              <div className="my-3 d-flex align-items-center justify-content-between">
                <Link
                  to={`/activate`}
                  className="btn text-capitalize Lexend fw-600"
                >
                  verify mail
                </Link>{" "}
              </div>
              <div className="d-flex justify-content-end py-3">
                <button
                  type="button"
                  onClick={() => setResend(true)}
                  className="btn textColor text-decoration-none fw-600 text-capitalize"
                >
                  Resend Activation token
                </button>{" "}
              </div>
            </form>
          </div>
        </div>
      </div>
      <ResendModal resend={resend} setResend={setResend} />
    </div>
  );
};

export default Login;

export const ResendModal = ({ resend, setResend }) => {
  let [stateData, setStateData] = useState({
    email: "",
  });
  let [loading, setLoading] = useState(false);

  let handleSubmit = async (e) => {
    e.preventDefault();
    if (!stateData.email) return;
    setLoading(true);
    try {
      var res = await axios.patch(`/api/v2/user/resend-otp`, {
        email: stateData?.email,
      });
      // console.log({ res: res?.data });
      toast.success(res?.data.msg);
      setResend(false);
    } catch (err) {
      let error = err.response?.data?.error;
      if (error) {
        error.forEach((error) => toast.error(error.msg));
      }
      if (err?.response?.status === 429 || err?.response?.status === 405)
        toast.error(err?.response?.data ? err?.response?.data : err?.message);
    }
    setLoading(false);
  };

  let textChange =
    (name) =>
    ({ target: { value } }) => {
      setStateData({ ...stateData, [name]: value });
    };

  return (
    <>
      <ModalComponents
        isOpen={resend}
        toggle={() => setResend(false)}
        title={"Resend Activation Token"}
      >
        <PasswordBox
          stateData={stateData}
          textChange={textChange}
          loading={loading}
          handleSubmit={handleSubmit}
        />
      </ModalComponents>
    </>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../Data/Context";
// import { Container } from "reactstrap";
import { EmptyComponent } from "../Utils";

const MainFaqs = () => {
  let { setStateName, faqs, manageFaqs } = useContext(GlobalState),
    [state, setState] = useState(null);

  useEffect(() => {
    setState(faqs?.data);
  }, [faqs?.data]);

  useEffect(() => {
    setStateName("FAQs");
    manageFaqs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="bg-white aboutScreen">
        <div className="py-5 tw-px-5 md:tw-px-10">
          <div className="py-3">
            {state?.length === 0 ? (
              <EmptyComponent />
            ) : (
              state?.map((item, i) => (
                <div className="py-3" key={i}>
                  <details className="rounded p-3">
                    <summary>{item?.summary}</summary>
                    <div className="d-flex flex-column">
                      <pre className="noScroll">{item?.details}</pre>
                    </div>
                  </details>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainFaqs;

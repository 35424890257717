import React, { useContext, useState } from "react";
import { ModalComponents } from "../DefaultHeader";
import { Buttons } from "../../Utils";
import { GlobalState } from "../../Data/Context";
import Phone from "../../Assets/phone.svg";
import Lock from "../../Assets/lock.svg";
import NameIcon from "../../Assets/name.svg";
import CalendarIcon from "../../Assets/icons8-calendar.png";
import TextInput from "../Inputs/textinput";

const Kyc = ({ isOpen, back, setKycType }) => {
  const { KycScreening, auth } = useContext(GlobalState);
  const [state, setState] = useState({
    firstName: auth?.user?.firstName || "",
    lastName: auth?.user?.lastName || "",
    middleName: auth?.user?.middleName || "",
    phoneNumber: auth?.user?.telephone || "",
    bvn: "",
    // nin: auth?.user?.ninNumber || "",
    birthday: auth?.user?.birthday || "",
  });
  const [loading, setLoading] = useState(false);

  let textChange =
    (name) =>
    ({ target: { value } }) => {
      setState({ ...state, [name]: value });
    };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await KycScreening(state);
    setLoading(false);
    setKycType(false);
  };

  return (
    <ModalComponents title={"KYC"} isOpen={isOpen} back={back} size="md">
      <div className="tw-flex tw-flex-col tw-gap-2 tw-overflow-hidden">
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
          <h2 className=" tw-font-extrabold ">Verify your account</h2>
          <span className="tw-font-semibold tw-text-xl tw-text-center">
            By verifying your document, you comply to CBN BVN verification
            directory
          </span>
        </div>

        <span className="tw-text-base tw-text-red-500 tw-font-extrabold tw-mb-2">
          Note: Please use valid names that match the details on your National
          ID card. Your BVN is required to obtain virtual accounts.
        </span>
        <form onSubmit={handleSubmit}>
          <div className="tw-grid tw-grid-cols-1 tw-gap-4 tw-md:tw-grid-cols-2">
            <TextInput
              type={"text"}
              name={"firstName"}
              Icon={NameIcon}
              img={true}
              value={state.firstName}
              placeholder={"First Name"}
              onChange={textChange("firstName")}
              required={true}
            />

            <TextInput
              type={"text"}
              name={"middleName"}
              Icon={NameIcon}
              img={true}
              value={state.middleName}
              placeholder={"Middle Name"}
              onChange={textChange("middleName")}
              required={true}
            />
            <TextInput
              type={"text"}
              name={"lastName"}
              Icon={NameIcon}
              img={true}
              value={state.lastName}
              placeholder={"Last Name"}
              onChange={textChange("lastName")}
              required={true}
            />
            <TextInput
              type={"tel"}
              name={"phoneNumber"}
              Icon={Phone}
              maxLength={11}
              img={true}
              value={state.phoneNumber}
              placeholder={"Phone Number"}
              onChange={textChange("phoneNumber")}
              required={true}
              // readOnly={true}
            />
            <TextInput
              type={"tel"}
              name={"bvn"}
              Icon={Lock}
              maxLength={11}
              img={true}
              value={state.bvn}
              placeholder={"BVN"}
              onChange={textChange("bvn")}
              required={true}
            />

            <TextInput
              type={"date"}
              name={"birthday"}
              Icon={CalendarIcon}
              img={true}
              value={state.birthday}
              placeholder={"Birthday"}
              onChange={textChange("birthday")}
              required={true}
            />
          </div>
          <div className="d-flex justify-content-end my-3">
            <div className="d-flex align-items-center">
              <Buttons
                onClick={handleSubmit}
                loading={loading}
                width=""
                css="btn-primary1 text-capitalize px-md-4 px-2 mx-1 mx-md-3 py-1 py-md-2 fontReduce2 text-white"
                title={"Continue"}
              />
              <button
                className={`btn btn-outline-primary1 text-capitalize px-md-4 px-2 mx-1 mx-md-3 py-1 py-md-2 fontReduce2 text-primary1`}
                onClick={() => setKycType(false)}
              >
                cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </ModalComponents>
  );
};

export default Kyc;

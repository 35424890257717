import React, { useContext, useEffect } from "react";
import { GlobalState } from "../../Data/Context";
// import { Container } from "reactstrap";
import { BonusCommission } from "../../Components/Wallets";

const Commissions = () => {
  let { setStateName } = useContext(GlobalState);
  useEffect(() => {
    setStateName("Transactions");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="bg-white aboutScreen">
      <div className="py-3 py-md-5 tw-px-5 md:tw-px-10">
        <h5 className="Lexend">Commission History</h5>
        <BonusCommission />{" "}
      </div>{" "}
    </div>
  );
};

export default Commissions;

import React, { useState } from "react";
// import { Container } from "reactstrap";
import TransactionsFolder, { TopFolder } from "../../Components/Transactions";

const MainTransactions = () => {
  let [subActive, setSubActive] = useState(0);
  return (
    <div className="bg-white">
      <div className="tw-px-5 md:tw-px-10">
        <TopFolder setSubActive={setSubActive} />
        <TransactionsFolder subActive={subActive} />
      </div>
    </div>
  );
};

export default MainTransactions;
